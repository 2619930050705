import { Button, Checkbox, DatePicker, Divider, Flex, Form, Input, Radio, theme } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Title from "antd/lib/typography/Title";
import { MailOutlined, UsergroupAddOutlined, UserOutlined } from "@ant-design/icons";
import { useUserData } from "store/user";
import { AuthType } from "constants/auth";
import { useCallback } from "react";
import { useRoot } from "store/root";
import { ResultTypes } from "constants/general";


const { useToken } = theme;

export const Registration = () => {
  const [form] = Form.useForm<RegistrationForm>();

  const navigate = useNavigate();

  const { token } = useToken();
  const {
    setResult,
  } = useRoot();

  const {
    isLoginInProgress,
    setAuthType,
    registerUser,
  } = useUserData();

  const handleSubmit = useCallback(async (data: RegistrationForm) => {

    const success = await registerUser({ ...data, dateOfBirth: data.dateOfBirth.toISOString() });

    if (success) {
      setResult({
        title: "հաջողվեց",
        type: ResultTypes.Success,
        subtitle: `Մենք հաստատման նամակ ենք ուղարկել հետևյալ հասցեին՝ ${data.email}, խնդրում ենք ստուգել Ձեր էլ. փոստի հասցեն։`,
      });

      navigate("/result");
    }

  }, [ setResult, navigate,registerUser]);

  return (
    <>
      <Title
        level={4}
        style={{ cursor: "pointer", textDecoration: "underline", color: token.colorLink }}
        onClick={() => setAuthType(AuthType.Login)}
      >
        ՄՈՒՏՔ
      </Title>
      <Divider style={{ margin: "12px 0" }} />
      <Flex justify="center">
        <Title level={3}>Գրանցվեք Հիմա!</Title>
      </Flex>
      <Form name={"Գրանցում"} layout={"horizontal"} size={"large"} onFinish={handleSubmit}
      form={form}>
        <Form.Item
          name={"email"}
          validateTrigger={['onChange', 'onBlur']}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          labelAlign={"right"}
          rules={[
            {
              type: "email",
              message: "Խնդրում ենք մուտքագրել վավեր էլ. փոստի հասցե!",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <Input prefix={<MailOutlined />} placeholder={"  Էլեկտրոնային փոստ *"} />
        </Form.Item>
        <Form.Item
          name={"firstName"}
          validateTrigger={['onChange', 'onBlur']}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              type: "string",
              message: "Խնդրում ենք մուտքագրել վավեր Անուն!",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <Input prefix={<UserOutlined />} placeholder={"  Անուն *"} />
        </Form.Item>
        <Form.Item
          name={"lastName"}
          validateTrigger={['onChange', 'onBlur']}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              type: "string",
              message: "Խնդրում ենք մուտքագրել վավեր Ազգանուն!",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <Input prefix={<UsergroupAddOutlined />} placeholder={"  Ազգանուն *"} />
        </Form.Item>
        <Form.Item
          name={"dateOfBirth"}
          validateTrigger={['onChange', 'onBlur']}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              type: "date",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <DatePicker placeholder={" Ծննդյան Ամսաթիվը *"} style={{ width: "100%" }} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          name={"gender"}
          validateTrigger={['onChange', 'onBlur']}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              type: "number",
              message: "Խնդրում ենք մուտքագրել վավեր էլփոստի հասցե!",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <Radio.Group>
            <Radio value={1}>Արական</Radio>
            <Radio value={2}>Իգական</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="agree"
          validateTrigger={['onChange', 'onBlur']}
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error("Շարունակելու համար խնդրում ենք համաձայնել օգտագործման պայմաններին:")),
            },
          ]}
        >
          <Checkbox>
            <Link to={"/terms"} target="_blank">
              Ընդհանուր դրույթներն ու պայմանները
            </Link>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit" style={{ marginTop: "15px" }} loading={isLoginInProgress}>
            ԳՐԱՆՑՈՒՄ
          </Button>
        </Form.Item>

      </Form>
    </>
  );
};