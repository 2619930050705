import { useEffect } from "react";
import { useRoot } from "store/root";
import { ResultTypes } from "constants/general";
import { useNavigate } from "react-router-dom";


export const NotFound = () => {
  const { setResult } = useRoot();
  const navigate = useNavigate();
  useEffect(() => {
    setResult({
      title: "Էջը չի գտնվել",
      subtitle: "",
      type: ResultTypes.NotFound,
    });
    navigate("/result");
  }, [navigate,setResult]);

  return null;
};