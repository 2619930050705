import { ThemeConfig } from "antd";
export enum Colors {
  Secondary = "#003A6C",
  HeaderColor = "#F5F7F9",
  SecondaryButton = "#80a9af"
}

export const designConfig: ThemeConfig = {
  hashed: false,
  token: {
    borderRadius: 14,
    colorPrimary: '#203a8e',
    colorTextSecondary: "#205B91",
    colorText: "rgba(0, 0, 0, 0.65)",
    colorBgBase: '#e4e7e8',
    colorLink: "#164671",
    colorBorder: '#39444D',
    colorError: '#F05454',
    colorSuccess: '#52C41A',
    colorWarning: '#FF6D3C',
  },
}

