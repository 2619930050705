import { Layout, Spin } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Suspense, useEffect } from "react";
import { Content as Main } from "antd/lib/layout/layout";
import { Auth } from "components/auth/Auth";
import { Header } from "components/header/Header";
import ErrorBoundary from "components/error/ErrorBoundry";
import AppFooter from "components/footer/Footer";
import { Messages } from "components/messages/Messages";
import { LoadingOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import { BottomNavigation } from "pages/homepage/BottomNavigation";
import { isMobile } from "constants/general";
import { useUserData } from "store/user";

const { BackTop } = FloatButton;

export const Content = () => {
  const { needToLogin } = useUserData();
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (needToLogin) {
      useUserData.setState({ needToLogin: false });
      navigate("/result");
    }
  }, [needToLogin,navigate]);
  return (
    <Layout>
      <BackTop style={{ zIndex: 99999, marginBottom: "40px" }} />
      <ErrorBoundary>
        <Messages />
      </ErrorBoundary>
      <ErrorBoundary>
        <Main style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}>
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>
          <Suspense fallback={<Spin percent={"auto"} indicator={<LoadingOutlined spin />} fullscreen />}>
            <div style={{ flex: "1 0 auto" }}>
              <Outlet />
            </div>
          </Suspense>
          <div style={{ flexShrink: 0 }}>
            <AppFooter />
          </div>
          {isMobile && (
            <div style={{ marginTop: "55px" }}>
              <BottomNavigation />
            </div>
          )}
        </Main>
      </ErrorBoundary>
      <ErrorBoundary>
        <Auth />
      </ErrorBoundary>
    </Layout>
  );
};