import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Content } from "components/conent/Content";
import { lazy } from "react";
import { NotFound } from "./NotFound";

const Result = lazy(() => import("pages/result/Result"));
const Resume = lazy(() => import("pages/resume/Resume"));
const Homepage = lazy(() => import("pages/homepage/Homepage"));
const CareerMap = lazy(() => import("pages/career/CareerMap"));
const UsefulLink = lazy(() => import("pages/info/UsefulLinks"));
const CareerFinder = lazy(() => import("pages/career/CareerFinder"));
const CareerContainer = lazy(() => import("pages/career/CareerContainer"));
const DownloadResults = lazy(() => import("pages/career/DownloadResults"));
const TermsAndConditions = lazy(() => import("pages/terms/TermsAndConditions"));
const CareerOrientationResult = lazy(() => import("pages/career/CareerOrientationResult"));

const router = createBrowserRouter([
  {
    element: <Content />,
    children: [
      {
        path: "/", element: <Homepage />,
      },
      {
        path: "/terms",
        element: <TermsAndConditions />,
      },
      {
        path: "/result",
        element: <Result />,
      },
      {
        path: "/resume",
        element: <Resume />,
      },
      {
        path: "/careerFinder",
        element: <CareerFinder />,
      },
      {
        path: "/career/:groupId",
        element: <CareerContainer />,
      },
      {
        path: "/useful/links",
        element: <UsefulLink />,
      },
      {
        path: "/careerResult",
        element: <CareerOrientationResult />,
      },
      {
        path: "/results/download",
        element: <DownloadResults />,
      },
      {
        path: "/career/map",
        element: <CareerMap />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);


const Routes = () => {

  return (
    <RouterProvider router={router} />
  );
};

export default Routes;