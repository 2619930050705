import { Button, Divider, Flex, Form, Input, theme } from "antd";
import { useUserData } from "store/user";
import Title from "antd/lib/typography/Title";
import { AuthType } from "constants/auth";
import { MailOutlined } from "@ant-design/icons";
import { useCallback } from "react";
import { useRoot } from "store/root";
import { ResultTypes } from "constants/general";
import { useNavigate } from "react-router-dom";


const { useToken } = theme;

export const Login = () => {
  const [form] = Form.useForm<Pick<RegistrationForm, "email">>();

  const { token } = useToken();
  const {
    setResult,
  } = useRoot();
  const navigate = useNavigate();
  const {
    isLoginInProgress,
    setAuthType,
    loginUser,
  } = useUserData();

  const handleSubmit = useCallback(async (data: Pick<RegistrationForm, "email">) => {
    const { errors, success } = await loginUser(data);
    if (errors.length) {
      form.setFields([
        {
          name: "email",
          errors: [errors[0].message],
        },
      ]);
    }

    if (success) {
      setResult({
        title: "հաջողվեց",
        type: ResultTypes.Success,
        subtitle: `Մենք նամակ ենք ուղարկել հետևյալ հասցեին՝ ${data.email}, խնդրում ենք ստուգել Ձեր էլ. փոստի հասցեն։`,
      });
      navigate("/result");
    }

  }, [loginUser, form, setResult,navigate]);


  return (
    <>
      <Title
        level={4}
        style={{ cursor: "pointer", textDecoration: "underline", color: token.colorLink }}
        onClick={() => setAuthType(AuthType.Registration)}
      >
        ԳՐԱՆՑՈՒՄ
      </Title>
      <Divider style={{ margin: "12px 0" }} />
      <Flex justify="center">
        <Title level={3}>ՄՈՒՏՔ</Title>
      </Flex>
      <Form
        form={form}
        name={"login"}
        layout={"horizontal"}
        size={"large"}
        onFinish={handleSubmit}
      >
        <Form.Item
          name={"email"}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          labelAlign={"right"}
          rules={[
            {
              type: "email",
              message: "Խնդրում ենք մուտքագրել վավեր էլ. փոստի հասցե!",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <Input prefix={<MailOutlined />} placeholder={"  Էլեկտրոնային փոստ *"} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit" loading={isLoginInProgress}>
            ՄՈՒՏՔ
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};