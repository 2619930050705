import { useRoot } from "store/root";
import { message } from 'antd';


const { useMessage } = message;

export const Messages = () => {

  const [messageApi, contextHolder] = useMessage();

  const {
    message,
    clearMessage
  } = useRoot();

  if (message?.destroy) {
    messageApi.destroy();
  }

  if (message) {
    messageApi.open({
      type: message.type,
      content: message.message,
      duration: 4
    }).then(() => {
      clearMessage();
      messageApi.destroy();
    })
  }

  return <>{contextHolder}</>;
}