import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import StorageManager from "helpers/api/StorageManager";
import { useUserData } from "store/user";
import { AuthType } from "constants/auth";
import { useRoot } from "store/root";
import { ResultTypes } from "constants/general";

class ApiService {
  private apiClient: AxiosInstance;

  constructor() {
    this.apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.apiClient.interceptors.request.use(
      (config) => {
        const token = StorageManager.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error: AxiosError) => Promise.reject(error),
    );

    this.apiClient.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (error.response && error.response.status === 401 && !useUserData.getState().isLoginInProgress) {
          useUserData.getState().setAuthType(AuthType.Login);
          useUserData.setState({ needToLogin: true });
          useRoot.getState().setResult({
            title: "Շարունակելու համար մուտք գործեք",
            subtitle: "",
            type: ResultTypes.NotLoggedIn,
          });
        }
        return Promise.reject(error);
      },
    );
  }

  private async handleResponse<T>(response: AxiosResponse<ApiResponse<T>>): Promise<ApiResponse<T>> {
    const { data, errors, success } = response.data;

    if (errors.length) {
      return { data: null, errors, success };
    }

    if (!success) {
      return {
        data: null,
        errors: [{ title: "Request failed", message: "Request failed with no specific error details." }],
        success,
      };
    }

    return { data, errors: [], success };
  }

  public async get<T>(url: string, params?: any): Promise<ApiResponse<T>> {
    try {
      const response = await this.apiClient.get<ApiResponse<T>>(url, { params });
      return this.handleResponse<T>(response);
    } catch (error) {
      if (error instanceof Error) {
        return { data: null, errors: [{ title: "Unexpected Error", message: error.message }], success: false };
      }
      return {
        data: null,
        errors: [{ title: "Unexpected Error", message: "An unexpected error occurred." }],
        success: false,
      };
    }
  }

  public async post<T>(url: string, data?: any): Promise<ApiResponse<T>> {
    try {
      const response = await this.apiClient.post<ApiResponse<T>>(url, data);
      return this.handleResponse<T>(response);
    } catch (error) {
      if (error instanceof Error) {
        return { data: null, errors: [{ title: "Unexpected Error", message: error.message }], success: false };
      }
      return {
        data: null,
        errors: [{ title: "Unexpected Error", message: "An unexpected error occurred." }],
        success: false,
      };
    }
  }

  public async put<T>(url: string, data?: any): Promise<ApiResponse<T>> {
    try {
      const response = await this.apiClient.put<ApiResponse<T>>(url, data);
      return this.handleResponse<T>(response);
    } catch (error) {
      if (error instanceof Error) {
        return { data: null, errors: [{ title: "Unexpected Error", message: error.message }], success: false };
      }
      return {
        data: null,
        errors: [{ title: "Unexpected Error", message: "An unexpected error occurred." }],
        success: false,
      };
    }
  }

  public async patch<T>(url: string, data?: any): Promise<{ data: T | null, errors: ApiError[] }> {
    try {
      const response = await this.apiClient.patch<ApiResponse<T>>(url, data);
      return this.handleResponse<T>(response);
    } catch (error) {
      if (error instanceof Error) {
        return { data: null, errors: [{ title: "Unexpected Error", message: error.message }] };
      }
      return { data: null, errors: [{ title: "Unexpected Error", message: "An unexpected error occurred." }] };
    }
  }

  public async delete<T>(url: string, params?: any): Promise<{ data: T | null, errors: ApiError[] }> {
    try {
      const response = await this.apiClient.delete<ApiResponse<T>>(url, { params });
      return this.handleResponse<T>(response);
    } catch (error) {
      if (error instanceof Error) {
        return { data: null, errors: [{ title: "Unexpected Error", message: error.message }] };
      }
      return { data: null, errors: [{ title: "Unexpected Error", message: "An unexpected error occurred." }] };
    }
  }
}

const ApiManager = new ApiService();

export default ApiManager;
