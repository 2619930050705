import { Avatar, Button, DatePicker, Divider, Flex, Form, Input, Radio, theme } from "antd";
import { useUserData } from "store/user";
import { AuthType } from "constants/auth";
import Title from "antd/lib/typography/Title";
import { Colors } from "constants/antTheme";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useRoot } from "store/root";
import { isEqual } from "lodash";
import ApiManager from "helpers/api/ApiManager";
import { MessageType } from "constants/general";
import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

type UserForm = Omit<RegistrationForm, "agree">

const { useToken } = theme;

export const Profile = () => {
  const { token } = useToken();
  const [form] = Form.useForm<UserForm>();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    setMessage,
  } = useRoot();

  const {
    userData,
    setAuthType,
    logoutUser,
  } = useUserData();

  const redirect = useNavigate();

  const logOut = () => {
    logoutUser();
    redirect("/")
  }


  const handleSubmit = useCallback((data: UserForm) => {
    setIsLoading(true);
    ApiManager.put("/api/Users/UpdateUserProfile", {
      ...data,
      dateOfBirth: data.dateOfBirth.toISOString(),
    })
      .then(({ success }) => {
        setMessage({
          destroy: true,
          type: success ? MessageType.Success : MessageType.Error,
          message: success ? "Ձեր տվյալները հաջողությամբ թարմացվեցին։" : "Ձեր տվյալների թարմացումը ձախողվեց։ Խնդրում ենք կրկին փորձել։",
        });
      }).catch(() => {
    })
      .finally(() => setIsLoading(false));
  }, [setMessage]);

  if (!userData) {
    setAuthType(AuthType.None);
    return null;
  }

  const initialValues = {
    ...userData,
    dateOfBirth: dayjs(userData.dateOfBirth),
  };

  const checkIfFormIsDirty = (_: UserForm, allValues: UserForm) => {
    setIsDirty(
      !isEqual(
        { ...allValues, dateOfBirth: allValues.dateOfBirth.toISOString() },
        { ...initialValues, dateOfBirth: initialValues.dateOfBirth.toISOString() },
      ),
    );
  };

  return (
    <div style={{ height: '100%', overflowY: 'auto', paddingBottom: '80px' }}>
      <Flex justify={"center"}>
        <Avatar size={40} style={{
          color: Colors.SecondaryButton,
          background: Colors.Secondary,
        }}>{userData.firstName.charAt(0)}</Avatar>
      </Flex>
      <Flex justify="center">
        <Title level={3}>Անձնական տվյալներ</Title>
      </Flex>
      <Divider style={{ margin: "12px 0" }} />
      <Form
        onValuesChange={checkIfFormIsDirty}
        name={"user"}
        initialValues={initialValues}
        layout={"horizontal"}
        size={"large"}
        onFinish={handleSubmit}
        form={form}>
        <Form.Item
          name={"email"}
          validateTrigger={["onChange", "onBlur"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={"էլ. փոստ"}
          labelAlign={"right"}
          rules={[
            {
              type: "email",
              message: "Խնդրում ենք մուտքագրել վավեր էլ. փոստի հասցե!",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <Input placeholder={"  Էլեկտրոնային փոստ *"} />
        </Form.Item>
        <Form.Item
          name={"firstName"}
          label={"Անուն"}
          validateTrigger={["onChange", "onBlur"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              type: "string",
              message: "Խնդրում ենք մուտքագրել վավեր Անուն!",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <Input placeholder={"  Անուն *"} />
        </Form.Item>
        <Form.Item
          name={"lastName"}
          validateTrigger={["onChange", "onBlur"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={"Ազգանուն"}
          rules={[
            {
              type: "string",
              message: "Խնդրում ենք մուտքագրել վավեր Ազգանուն!",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <Input placeholder={"  Ազգանուն *"} />
        </Form.Item>
        <Form.Item
          name={"dateOfBirth"}
          validateTrigger={["onChange", "onBlur"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={"Ծննդյան Ամսաթիվ"}
          rules={[
            {
              type: "date",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}
        >
          <DatePicker placeholder={" Ծննդյան Ամսաթիվը *"} style={{ width: "100%" }} format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          name={"gender"}
          validateTrigger={["onChange", "onBlur"]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              type: "number",
            },
            {
              required: true,
              message: "Այս դաշտը պարտադիր է!",
            },
          ]}>
          <Radio.Group>
            <Radio value={1}>Արական</Radio>
            <Radio value={2}>Իգական</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit" style={{ marginTop: "15px" }} disabled={!isDirty} loading={isLoading}>
            Թարմացնել
          </Button>
        </Form.Item>
      </Form>
      <Divider/>
      <Flex justify="center">
        <Title
          level={4}
          style={{ cursor: "pointer", textDecoration: "underline", color: token.colorLink }}
          onClick={logOut}
        >
          Դուրս գալ <LogoutOutlined />
        </Title>
      </Flex>
    </div>
  );
};