interface StorageService<T = unknown> {
  getItem(key: string): T | null;
  setItem(key: string, value: T): void;
  removeItem(key: string): void;
  clear(): void;
}

class LocalStorageService<T = unknown> implements StorageService<T> {
  getItem(key: string): T | null {
    const item = localStorage.getItem(key);
    return item ? item as T : null;
  }

  setItem(key: string, value: T): void {
    localStorage.setItem(key, value as string);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}

const storageService = new LocalStorageService();

export default storageService;