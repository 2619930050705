import React from "react";
import { Menu } from "antd";
import {
  HomeOutlined,
  GlobalOutlined,
  RocketOutlined,
  DownloadOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { Colors } from "constants/antTheme";
import { theme } from "antd";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "constants/general";

const { useToken } = theme;

export const AppMenu = () => {

  const location = useLocation();

  const { token } = useToken();

  const menuItems = [
    {
      key: "home",
      label: "Գլխավոր էջ",
      icon: <HomeOutlined style={{ color: token.colorPrimary, fontSize: "18px" }} />,
      to: "/",
    },
    {
      key: "career",
      label: "Կարիերա",
      icon: <RocketOutlined style={{ color: token.colorPrimary, fontSize: "20px" }} />,
      to: "/careerFinder",
    },
    {
      key: "settings",
      label: "Օգտակար հղումներ",
      icon: <GlobalOutlined style={{ color: token.colorPrimary, fontSize: "18px" }} />,
      to: "/useful/links",
    },
    {
      key: "download",
      label: "Ներբեռնել Արդյունքները",
      icon: <DownloadOutlined style={{ color: token.colorPrimary, fontSize: "18px" }} />,
      to: "/results/download",
    },
    {
      key: "resume",
      label: "Ռեզումե",
      icon: <FileDoneOutlined style={{ color: token.colorPrimary, fontSize: "18px" }} />,
      to: "/resume"
    }
  ];

  const getActiveKey = (path: string) => {
    switch (path) {
      case "/":
        return "home";
      case "/careerFinder":
        return "career";
      case "/useful/links":
        return "settings";
     case "/results/download":
       return "download";
      default:
        return path.includes("career") ? "career" : "";
    }
  };

  if (isMobile) {
    return null;
  }

  return (
    <div>
      <Menu mode="horizontal" selectedKeys={[getActiveKey(location.pathname)]}
            style={{ background: Colors.HeaderColor }}>
        {menuItems.map(item => (
          <Menu.Item key={item.key} icon={item.icon}>
            <Link to={item.to}>
              {item.label}
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};
