import { Header as HeaderAnt } from "antd/es/layout/layout";
import { Colors } from "constants/antTheme";
import { Image } from "antd";
import { isMobile } from "constants/general";
import { Link } from "react-router-dom";
import { UserBlock } from "components/header/UserBlock";
import { AppMenu } from "pages/homepage/AppMenu";

export const Header = () => {
  return (
    <HeaderAnt style={{
      position: "sticky",
      top: 0,
      zIndex: 100,
      background: Colors.HeaderColor,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      padding: isMobile ? "0px 10px" : "15px",
    }}>
      <div>
        <Link
          to="/"
          style={{
            width: isMobile ? "110px" : "250px",
            maxHeight: "50px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image
            src="/logo.svg"
            alt="Logo"
            style={{
              width: "100%",
              height: "auto",
              maxHeight: "40px",
              objectFit: "contain",
            }}
            preview={false}
          />
        </Link>
      </div>
      <AppMenu />
      <UserBlock />
    </HeaderAnt>
  );
};