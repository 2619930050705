

export const OTP_QUERY_KEY = "otp"

export enum AuthType {
  Login = 'login',
  Registration = 'registration',
  Profile = 'profile',
  None = 'none',
}
