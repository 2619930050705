import {
  DownloadOutlined,
  GlobalOutlined,
  HomeOutlined,
  RocketOutlined,
  MenuOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { Drawer, theme } from "antd";
import { Link, useLocation } from "react-router-dom";

const { useToken } = theme;

export const BottomNavigation = () => {
  const { token } = useToken();
  const location = useLocation();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const menuItems = [
    { key: "home", label: "Գլխավոր էջ", icon: <HomeOutlined />, to: "/" },
    { key: "career", label: "Կարիերա", icon: <RocketOutlined />, to: "/careerFinder" },
    { key: "download", label: "Արդյունքներ", icon: <DownloadOutlined />, to: "/results/download" },
    { key: "settings", label: "Հղումներ", icon: <GlobalOutlined />, to: "/useful/links" },
    {key: "resume", label: "Ռեզումե", icon: <FileDoneOutlined />, to: "/resume" },
  ];

  const visibleMenuItems = menuItems.slice(0, 3);

  const toggleDrawer = () => {
    setDrawerVisible(!drawerVisible);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          backgroundColor: token.colorBgBase,
          zIndex: 1000000,
          paddingBottom: "11px",
          boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        {visibleMenuItems.map(item => (
          <Link to={item.to} key={item.key} style={{ textAlign: "center", color: "inherit", flexBasis: "33.3%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "4px 8px",
                position: "relative",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  color: (location.pathname === item.to || (item.to === "/careerFinder" && location.pathname.includes("career"))) ? token.colorPrimary : token.colorText,
                  transition: "color 0.3s",
                }}
              >
                {item.icon}
              </div>
              <span
                style={{
                  marginTop: "4px",
                  fontSize: "12px",
                  fontWeight: (location.pathname === item.to || (item.to === "/careerFinder" && location.pathname.includes("career"))) ? "bold" : "normal",
                  color: location.pathname === item.to ? token.colorPrimary : token.colorText,
                  transition: "color 0.3s",
                }}
              >
                {item.label}
              </span>
              {(location.pathname === item.to || (item.to === "/careerFinder" && location.pathname.includes("career"))) && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "-4px",
                    height: "4px",
                    width: "100%",
                    backgroundColor: token.colorPrimary,
                    borderRadius: "2px",
                  }}
                />
              )}
            </div>
          </Link>
        ))}

        <div
          style={{
            textAlign: "center",
            flexBasis: "33.3%",
            cursor: "pointer",
          }}
          onClick={toggleDrawer}
        >
          <div
            style={{
              fontSize: "20px",
              color: token.colorText,
            }}
          >
            <MenuOutlined />
          </div>
          <span
            style={{
              marginTop: "4px",
              fontSize: "12px",
              color: token.colorText,
            }}
          >
            Մենյու
          </span>
        </div>
      </div>

      <Drawer
        title="Մենյու"
        placement="right"
        onClose={toggleDrawer}
        open={drawerVisible}
        styles={{body: {padding: 0}}}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px 0",
          }}
        >
          {menuItems.map(item => (
            <Link to={item.to} key={item.key} style={{ textAlign: "center", color: "inherit", width: "100%" }}
                  onClick={toggleDrawer}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "12px 16px",
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    color: location.pathname === item.to ? token.colorPrimary : token.colorText,
                    transition: "color 0.3s",
                  }}
                >
                  {item.icon}
                </div>
                <span
                  style={{
                    marginTop: "4px",
                    fontSize: "14px",
                    fontWeight: location.pathname === item.to ? "bold" : "normal",
                    color: location.pathname === item.to ? token.colorPrimary : token.colorText,
                    transition: "color 0.3s",
                  }}
                >
                  {item.label}
                </span>
                {location.pathname === item.to && (
                  <div
                    style={{
                      height: "4px",
                      width: "100%",
                      backgroundColor: token.colorPrimary,
                      borderRadius: "2px",
                      marginTop: "8px",
                    }}
                  />
                )}
              </div>
            </Link>
          ))}
        </div>
      </Drawer>
    </>
  );
};
