import { create } from "zustand";
import { AuthType } from "constants/auth";
import ApiManager from "helpers/api/ApiManager";
import StorageManager from "helpers/api/StorageManager";
import { noop } from "lodash";

export const useUserData = create<UserStore & UserActions>((set, getState) => ({
  errors: [],
  userData: null,
  needToLogin: false,
  callback: noop,
  isLoggedIn: false,
  isLoginInProgress: false,
  type: AuthType.None,
  setAuthType: (type: AuthType, callback?: VoidFunction) => set({ type, ...(callback && { callback }) }),
  loginUser: async (form: Pick<RegistrationForm, "email">) => {

    const {
      callback,
    } = getState();

    set({ isLoginInProgress: true });

    const { success, errors } = await ApiManager.post<AuthSuccessResponse>("/api/Users/Login", { ...form });


    if (success) {
      callback();
    }


    set({
      isLoginInProgress: false,
      ...(success && { type: AuthType.None, callback: noop }),
    });

    return { errors, success };
  },
  identifyUser: async (otp: string) => {

    set({ isLoginInProgress: true });

    const { data: token } = await ApiManager.post<{ data: string }>("/api/Auth/Autenticate", { token: otp });
    if (token) {
      StorageManager.setItem("token", token);
    }

    set({ isLoginInProgress: false });
  },
  registerUser: async (formData: RegistrationForm) => {

    const {
      callback
    } = getState();

    set({ isLoginInProgress: true });

    const { data, errors, success } = await ApiManager.post<AuthSuccessResponse>("/api/Users/Register", formData);
    if (errors.length && errors[0].errorCode === 999) {
      set({ isLoginInProgress: false, type: AuthType.Login });
    } else {
      set({
        ...(data && { data }),
        isLoginInProgress: false,
        ...(errors.length && { errors }),
        ...(success && { type: AuthType.None }),
      });
    }

    if (success) {
      callback();
    }

    return success;
  },
  restoreUser: async () => {
    set({ isLoginInProgress: true });

    const { data, success } = await ApiManager.get<User>("/api/Users/GetUserProfile");
    set({
      ...(success && { userData: data }),
      ...(success && { isLoggedIn: true }),
      isLoginInProgress: false,
    });
  },
  logoutUser: () => {
    StorageManager.clear();
    set({ isLoggedIn: false, isLoginInProgress: false, type: AuthType.None });
  },
}));