import React from "react";
import { Button, Typography, Card } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import { theme } from "antd";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const ErrorPage = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      width: "100%",

    }}>
      <Card
        style={{
          textAlign: "center",
          borderRadius: "8px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "600px",
          margin: "auto",
        }}
      >
        <FrownOutlined style={{ fontSize: "48px", color: token.colorError, marginBottom: "16px" }} />
        <Title level={3}>Վա՜յ, Ինչ-որ բան սխալ գնաց</Title>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginTop: "20px",
          }}
        >
          <Button
            type="primary"
            size="large"
            onClick={() => {
              navigate("/");
              window.location.reload();
            }}
            style={{ width: "100%" }}
          >
            Վերադառնալ գլխավոր էջ
          </Button>
          <Button
            size="large"
            onClick={() => window.location.reload()}
            style={{ width: "100%" }}
          >
            Վերբեռնել էջը
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default ErrorPage;
