import { Drawer, Modal } from "antd";
import { useUserData } from "store/user";
import { AuthType } from "constants/auth";
import { Login } from "components/auth/Login";
import { Registration } from "components/auth/Registration";
import { GENERAL_WIDTH_FOR_FORMS } from "constants/general";
import { Profile } from "components/auth/Profile";


export const Auth = () => {
  const {
    type,
    setAuthType
  } = useUserData();

  if (type === AuthType.None) {
    return null;
  }

  if (type === AuthType.Profile) {
   return (
     <Drawer open={Boolean(type)} closable={true} onClose={() => setAuthType(AuthType.None)}>
       <Profile/>
     </Drawer>
   )
  }

  return (
    <Modal open={Boolean(type)} footer={null} width={GENERAL_WIDTH_FOR_FORMS} centered height={"600px"} onCancel={() => setAuthType(AuthType.None)}>
      {(() => {
        switch (type) {
          case AuthType.Login:
            return <Login />;

          case AuthType.Registration:
            return <Registration />;

          default:
            return null;
        }
      })()}
    </Modal>
  );
};
