import React from "react";
import { Flex, Layout } from "antd";
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { isMobile } from "constants/general";
import { Colors } from "constants/antTheme";
import { Link } from "react-router-dom";

const { Footer } = Layout;


const SOCIAL_LINKS = [
  {
    key: 1,
    to: "https://www.facebook.com/AGBUArmenia",
    icon: <FacebookOutlined style={{ fontSize: "20px" }} />,
  },
  {
    key: 2,
    to: "https://www.instagram.com/agbuarmenia/",
    icon: <InstagramOutlined style={{ fontSize: "20px" }} />,
  },
  {
    key: 3,
    to: "https://x.com/AGBUarmenia",
    icon: <TwitterOutlined style={{ fontSize: "20px" }} />,
  },
  {
    key: 4,
    to: "https://www.youtube.com/user/AGBUvideo",
    icon: <YoutubeOutlined style={{ fontSize: "20px" }} />,
  },
];


const AppFooter = () => {
  return (
    <Footer style={{ background: Colors.HeaderColor }}>
      <Flex align={"center"} gap={isMobile ? 5 : 20} wrap={isMobile} justify={"space-evenly"}>
        <Flex align={"center"} gap={20} justify={isMobile ? "space-evenly" : "center"} style={{ ...isMobile && { width: "100%" } }}>
          <Link to={"/"}>Link</Link>
          <Link to={"/"}>Link</Link>
          <Link to={"/"}>Link</Link>
          <Link to={"/"}>Link</Link>
        </Flex>
        <Flex
          align={"center"}
          gap={isMobile ? 5 : 20}
          style={{ ...isMobile && { width: "100%" , margin: "10px 0" } }}
          justify={isMobile ? "space-evenly" : "center"}
        >
          {SOCIAL_LINKS.map(({ icon, to, key }) => (
            <Link to={to} key={key} target={"_blank"}>{icon}</Link>
          ))}
        </Flex>
        <div style={{ textAlign: "center", ...isMobile && { width: "100%" } }}>
          © {new Date().getFullYear()} AGBU/LEAP. All Rights Reserved.
        </div>
      </Flex>
    </Footer>
  );
};

export default AppFooter;
