import mobile from "is-mobile";


export const isMobile = mobile();

export enum ResultTypes {
  Error = "error",
  Success = "success",
  Failure = "failure",
  NotFound = "not-found",
  NotLoggedIn = "not-loggedIn",
}


export enum MessageType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Error = "error",
  Loading = "loading"
}

export const MOBILE_WIDTH = "85%";
export const WEB_WIDTH = "25%";


export const GENERAL_WIDTH_FOR_FORMS = isMobile ? MOBILE_WIDTH : WEB_WIDTH;