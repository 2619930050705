import Title from "antd/lib/typography/Title";
import { AuthType } from "constants/auth";
import { Flex, theme } from "antd";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { Colors } from "constants/antTheme";
import { useUserData } from "store/user";
import { Spin } from "antd";

const { useToken } = theme;


export const UserBlock = () => {

  const {
    token,
  } = useToken();

  const {
    isLoggedIn,
    setAuthType,
    isLoginInProgress,
  } = useUserData();

  if (isLoginInProgress) {
    return <Spin percent={"auto"} indicator={<LoadingOutlined spin />}/>;
  }

  return (
    <Flex justify={"center"} align={"center"} style={{ margin: "10px" }}>
      {
        !isLoggedIn ? (
          <>
            <div style={{ margin: "5px " }}>
              <Title
                level={5}
                style={{ cursor: "pointer", textDecoration: "underline", color: token.colorPrimary, margin: 0 }}
                onClick={() => setAuthType(AuthType.Login)}
              >
                ՄՈՒՏՔ
              </Title>
            </div>
            <div style={{ margin: "5px " }}>
              <Title
                level={5}
                style={{ cursor: "pointer", textDecoration: "underline", color: Colors.SecondaryButton, margin: 0 }}
                onClick={() => setAuthType(AuthType.Registration)}
              >
                ԳՐԱՆՑՈՒՄ
              </Title>
            </div>
          </>
        ) : <UserOutlined style={{ fontSize: "25px" }} onClick={() => setAuthType(AuthType.Profile)} />
      }
    </Flex>
  );
};