import { useCallback, useEffect, useState } from "react";
import { OTP_QUERY_KEY } from "constants/auth";
import { useUserData } from "store/user";


export const useBootstrap = () => {

  const [loading, setLoading] = useState(true);
  const {
    restoreUser,
    identifyUser,
  } = useUserData();

  const checkOtp = useCallback(async () => {
    const searchParams = new URLSearchParams(window.location.search);

    const otp = searchParams.get(OTP_QUERY_KEY);

    if (otp) {
      searchParams.delete(OTP_QUERY_KEY);

      await identifyUser(otp);
      const newUrl = searchParams.toString()
        ? `${window.location.pathname}?${searchParams.toString()}`
        : window.location.pathname;

      window.history.replaceState({}, "", newUrl);
      if (newUrl === "/") {
        window.location.href = "/careerFinder"
      }
    }
  }, [identifyUser]);


  const init = useCallback(async () => {
    await checkOtp();
    await restoreUser();
  }, [checkOtp,restoreUser]);


  useEffect(
    function bootstrapApp() {
      init()
        .then()
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }, [init],
  );


  return { loading };
};