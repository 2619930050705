import "antd/dist/reset.css";
import { ConfigProvider, Spin } from "antd";
import { designConfig } from "constants/antTheme";
import Routes from "nav/Routes";
import "./App.css";
import { useBootstrap } from "hooks/useBootstrap";

function App() {
  const { loading } = useBootstrap();

  if (loading) {
    return <Spin spinning percent={"auto"} fullscreen size={"large"} />;
  }

  return (
    <ConfigProvider theme={designConfig} componentSize={"large"}>
      <Routes />
    </ConfigProvider>
  );
}

export default App;
